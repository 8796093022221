<template>
  <div class="messages-block">
    <div class="header flex-row">
      <button @click="$emit('closePanel', {});" class="btn btn-transparent"><i class="fal fa-chevron-left"></i></button>

      <span class="flex-expand block-vcenter padding-half-h smaller">
        {{ theme.name }}
      </span>
      
      <tippy :interactive="true" placement="bottom-end" trigger="click" :maxWidth="500" theme="light sodnp" ref="themeTeamTippy" :onShow="onShowThemeTeam">
        <template v-slot:trigger>
          <button class="btn btn-transparent"><i class="fad fa-users"></i></button>
        </template>

        <div class="team-users">
          <user-entry :id="uid" v-for="uid in themeUserIds" :key="'themeTeam' + uid" />
          <a class="btn full-width margin-t" href="javascript:" v-if="isUsersEditVisible" @click="onShowThemeTeam(); $modal.show('PopupEditTaskTheme', {task: task, theme: theme})">
            <i class="fal fa-pencil"></i> Изменить участников
          </a>
        </div>
      </tippy>


      <button class="btn btn-transparent" @click="$modal.show('PopupEditTaskTheme', {task: task, theme: theme})"><i class="fal fa-cog"></i></button>
    </div>
    <div class="messages" :class="{customer: isCustomerInTheme}" ref="msgList">
      <vue-element-loading :active="isLoading"/>
      <div class="message" v-bind:class="{'mine': m.profileId == authId, 'has-files': m.files.length}" v-for="(m, index) in messages" :key="m.id">
          <div class="author">
            <avatar v-if="index == 0 || m.profileId != messages[index-1].profileId" :userid="m.profileId" />
          </div>
          <div class="content">
            <span class="bubble z-1">
              
              <template v-for="(file) in m.files">
                <span class="photo-cover thumb"
                  :key="file.id + 'thumb' + m.id"
                  v-if="file.thumbUrl"
                  @click.prevent="$modal.show('PopupFilePreview', file)"
                  v-bind:style="{'width': 100 / m.files.filter(f => f.thumbUrl).length + '%', 'background-image': 'url(' + file.thumbUrl + ')'}">
                  <i class="fas fa-eye"></i>
                </span>

                <div class="padding-h" v-else :key="file.id + 'msg' + m.id">
                  <file-entry :file="file">
                  </file-entry>
                </div>
              </template>
              <span class="text nl2br" v-linkified v-if="m.text">{{ m.text }}</span>
            </span>
          </div>
      </div>
    </div>
    <div class="typing" v-if="typingNames">{{ typingNames }}</div>
    <div class="compose no-padding">
      <!-- отправляемые файлы -->
      <div class="files padding-h" v-show="composing.files.length">
        <file-entry :file="file.response.id ? file.response : file" v-for="(file, index) in composing.files" :key="file.id">
          <template slot="right" v-if="!file.success">
            <div class="progressbar">
              <div class="bar" v-bind:style="{'width': + file.progress + '%'}"></div>
            </div>
          </template>
          <template slot="right" v-else>
            <button class="btn" @click="composing.files.splice(index, 1)">
              <i class="fal fa-times"></i>
            </button>
          </template>
        </file-entry>
      </div>
    </div>
    <div class="compose flex-row">
      <!-- поле ввода с кнопками -->
      <file-upload
        class="btn"
        name="file"
        :post-action="uploadUrl"
        :multiple="true"
        :maximum="5"
        :drop="true"
        :thread="3"
        :headers="authHeaders"
        input-id="theme_chat_upload"
        v-model="composing.files"
        ref="upload"
        @input-filter="inputFile">
        <i class="fal fa-paperclip"></i>
      </file-upload>

      <textarea-autosize
        :min-height="30"
        :max-height="150"
        v-model="composing.text" 
        @keydown.native.enter="handleEnter"
        @keydown.native="handleKeyup"
        @paste.native="onPaste"   />
      <button class="btn text-green" @click="sendMessage()"><i class="fad fa-paper-plane"></i></button>
    </div>
  </div>
</template>

<script>
import { NOTIFICATIONS_REQUEST } from "@/store/actions/notifications"
import { mapGetters } from "vuex";

import VueElementLoading from 'vue-element-loading'
import FileUpload from 'vue-upload-component'
import Avatar from '@/components/Avatar.vue';
import FileEntry from '@/components/FileEntry.vue';
import UserEntry from '@/components/UserEntry.vue';

import { apiCall, apiAuthHeaders, API_PREFIX } from "@/utils/api"

export default {
  name: 'TaskThemeChat',
  components: {
    VueElementLoading,
    FileEntry,
    FileUpload,
    Avatar,
    UserEntry
  },
  props: ['task', 'theme'],
  data() {
    return {
      isLoading: false,
      isSending: false,
      isUploading: false,
      messages: [],
      typing: [],
      typingHandlers: {},
      meTyping: false,
      meTypingHandler: null,
      composing: {
        text: "",
        files: [],
        taskId: this.task.id,
        themeId: this.theme.id,
      }
    }
  },
  computed: {
		...mapGetters(["authId", "allUsersKeyed"]),
    authHeaders() { return apiAuthHeaders() },
    uploadUrl() {
      return API_PREFIX + 'themes/upload/' + this.theme.id
    },
    typingNames() {
      var names = this.typing.filter(t => t != this.authId).map(t => this.allUsersKeyed[t].fullName())
      if (names.length == 0) {
        return ''
      }
      if (names.length == 1) {
        return names[0] + ' набирает сообщение'
      }
      return names.splice(0, names.length-1).join(', ') + ' и ' + names[0] + 'набирают сообщение'
    },
    themeUserIds() {
      let ids = []
      for (var i=0; i<this.theme.users.length; i++) {
        ids.push(this.theme.users[i].profileId)
      }
      return ids
    },
    isCustomerInTheme() {
      return this.theme.userIds.some(uid => this.allUsersKeyed[uid].isCustomer)
    },
    isUsersEditVisible() {
      // for all-free to join themes it's not needed
      return this.theme.type > 0
    }
	},
  mounted() {
    this.$sodnpHub.$on('task-theme-msg-received', this.onMessageReceived)
    this.$sodnpHub.$on('task-theme-msg-typing', this.onMessageTypingAdded)

    this.loadMessages()
  },
  beforeDestroy: function() {
    this.$sodnpHub.$off('task-theme-msg-received', this.onMessageReceived)
    this.$sodnpHub.$off('task-theme-msg-typing', this.onMessageTypingAdded)
  },
  methods: {
    onShowThemeTeam() {
        document.body.click(); // скрываем все открытые tippy
        return true
    },
    loadMessages() {
      this.isLoading = true
      apiCall({ url: 'tasks/'+this.theme.taskId+'/themes/' + this.theme.id, method: "GET"})
      .then((resp) => {
        for (var i=resp.length-1; i>=0; i--) {
          this.messages.push(resp[i])
        }
        this.isLoading = false
        this.$store.dispatch(NOTIFICATIONS_REQUEST)
        this.scrollToBottom()
      })
    },
    onMessageReceived(data) {
      if (this.theme.id != data.themeId) {
        return
      }
      this.messages.push(data)
      this.typing = this.typing.filter(t => t != parseInt(data.profileId))
      this.scrollToBottom()
      apiCall({ url: 'tasks/'+this.theme.taskId+'/themes/' + this.theme.id + '/read', method: "POST"})
      .then(() => {
        this.$store.dispatch(NOTIFICATIONS_REQUEST)
      })
    },
    onMessageTypingAdded(data) {
      if (data.themeId != this.theme.id) {
        return
      }
      console.log('someone typing', data)
      let id = parseInt(data.profileId)
      if (this.typingHandlers[id]) {
        clearTimeout(this.typingHandlers[id])
      } else {
        this.typing.push(id)
      }
      this.typingHandlers[id] = setTimeout(() => {
        this.onMessageTypingRemoved(id)
      }, 5000)
    },
    onMessageTypingRemoved(id) {
      this.typing = this.typing.filter(t => t != id)
      this.typingHandlers[id] = null
    },
    onPaste(pasteEvent, callback) {
      if(!pasteEvent.clipboardData || !pasteEvent.clipboardData.items){
        if(typeof(callback) == "function"){
          callback(undefined);
        }
      }

      var items = pasteEvent.clipboardData.items;
      for (var i=0; i<items.length; i++) {
          if (items[i].type.indexOf("image") === -1) {
            continue;
          }
          var asd = 123
          asd += 123
          console.log(asd)
          console.log("IMAGE FOUND")
          var blob = items[i].getAsFile();
          this.$refs.upload.add(blob)
      }

    },
    handleEnter(e) {
      if (e.shiftKey) {
        return
      }
      e.stopPropagation()
      e.preventDefault()
      this.sendMessage()
    },
    handleKeyup() {
      if (this.meTyping) {
        return false
      } else {
        this.sendTyping()
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.msgList.scrollTop = this.$refs.msgList.scrollHeight
      })
    },

    startUpload(file) {
      this.isUploading = true
      this.$refs.upload.update(file, {active: true})
    },
    // обновление статуса загружаемого файла
    inputFile(newFile, oldFile/*, prevent*/) {
      if (newFile && !oldFile) { // file added
        console.log(newFile)
        this.$nextTick(() => this.startUpload(newFile))
      }

      if (newFile && oldFile) {  // file updated
        if (newFile.success !== oldFile.success) {  // Uploaded successfully
          this.isUploading = this.composing.files.filter(f => !f.success).length > 0
        }
      }
    },
    sendTyping() {
      console.log('me typing')
      apiCall({ url: 'tasks/'+this.theme.taskId+'/themes/' + this.theme.id + '/typing', method: "POST"})
      clearTimeout(this.meTypingHandler)
      this.meTyping = true
      this.meTypingHandler = setTimeout(() => {
        this.meTyping = false
      }, 4000)
    },
    sendMessage() {
      if (this.isSending) {
        return
      }
      this.isSending = true
      var composed = Object.assign({}, this.composing)
      composed.files = []
      composed.fileIds = this.composing.files.filter(f => !!f.success).map(f => f.response.id)
      apiCall({ url: 'tasks/'+this.theme.taskId+'/themes/' + this.theme.id + '/send', method: "POST", data: composed})
      .then(() => {
        this.composing = {
          text: "",
          files: [],
          taskId: this.task.id,
          themeId: this.theme.id,
        }
        this.isSending = false
      }).catch(() => {
        this.isSending = false
      });
    }
  }
}
</script>

<style scoped>
.messages-block {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #509E2F;
  color: #FFF;
  box-sizing: border-box;
  font-size: 1.39em;
  font-weight: 700;
}

.messages {
  flex: 1 1 auto;
  overflow: auto;
  padding: .5em;
}
.messages.customer {
  background-color: #ffe2c0;
}

.messages .message {
  display: flex;
  flex-direction: row;
  margin-bottom: .5em;
}

.message .author {
  display: inline-block;
  width: 4em;
  min-width: 4em;
  max-width: 4em;
  text-align: center;
}

.message.mine {
  flex-direction: row-reverse;
}

.messages .message .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80%;
}

.messages .message.has-files .content {
  width: 100%;
}

.messages .message .bubble {
  display: inline-block;
  background-color: #f1f2f3;
  border-radius: 1em;
  overflow: hidden;
}
.messages .message .bubble .text {
  display: inline-block;
  padding: .25em .5em;
  word-break: break-word;
}
.messages .message.has-files .bubble .text {
  display: block;
}

.messages .thumb {
  display: inline-flex;
  height: 10rem;
  font-size: 2rem;
  cursor: pointer;
  color: #FFF;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-width: 33.333%;

  transition: all .2s ease-in-out;
}
.messages .thumb:hover {
  box-shadow: inset 0 0 20em rgba(0,0,0,0.3);
}

.messages .thumb > i {
  opacity: 0;
  transition: opacity .2s ease-in-out;
}
.messages .thumb:hover > i {
  opacity: 1;
}

.compose {
  background-color: #EEE;
  padding: .5em;
  align-items: flex-start;
}
.compose > .btn {
  border: none;
  border-radius: none;
  box-shadow: none;
  background-color: transparent;
  opacity: .6;
}
.compose > .btn i {
  font-size: 1.2rem;
}
.compose > .btn:hover {
  opacity: 1;
}

.compose .files {
  max-height: 10rem;
  overflow: auto;
  overflow: overlay;
}

.typing {
  font-size: .85em;
  color: #777;
  padding: .2em;
}
</style>