var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"name":"PopupAllStages","draggable":".popup-header","adaptive":true,"scrollable":true,"width":900,"height":"auto"},on:{"before-open":_vm.beforeOpen,"before-close":_vm.beforeClose}},[_c('div',{staticClass:"popup-header flex-row"},[_c('span',{staticClass:"flex-expand padding-half"},[_vm._v(_vm._s(_vm.task.name))]),(_vm.canHideStages)?_c('button',{staticClass:"btn",class:{
      pressed: _vm.filters.showHidden
    },on:{"click":function($event){_vm.filters.showHidden = !_vm.filters.showHidden}}},[_c('i',{staticClass:"fa-trash-alt",class:{
      fal: !_vm.filters.showHidden,
      fas: _vm.filters.showHidden
    }}),_vm._v(" Показать скрытые")]):_vm._e(),_c('button',{staticClass:"btn",class:{
      pressed: _vm.filters.unreadOnly
    },attrs:{"title":"Показать только непрочитанные"},on:{"click":function($event){_vm.filters.unreadOnly = !_vm.filters.unreadOnly}}},[_c('i',{staticClass:"fa-envelope",class:{
      fal: !_vm.filters.unreadOnly,
      fas: _vm.filters.unreadOnly
    }}),(_vm.unreadCount > 0)?_c('span',[_vm._v("("+_vm._s(_vm.unreadCount)+")")]):_vm._e()]),_c('button',{staticClass:"btn",class:{
      pressed: _vm.filters.informationalFilesOnly
    },attrs:{"title":"Содержащие новые информационные файлы"},on:{"click":function($event){_vm.filters.informationalFilesOnly = !_vm.filters.informationalFilesOnly}}},[_c('i',{staticClass:"fad fa-file-alt file-info"})]),_c('button',{staticClass:"btn",class:{
      pressed: _vm.filters.workFilesOnly
    },attrs:{"title":"Содержащие новые рабочие файлы"},on:{"click":function($event){_vm.filters.workFilesOnly = !_vm.filters.workFilesOnly}}},[_c('i',{staticClass:"fad fa-file-alt file-working"})]),_c('button',{staticClass:"btn",class:{
      pressed: _vm.filters.finalFilesOnly
    },attrs:{"title":"Содержащие новые итоговые файлы"},on:{"click":function($event){_vm.filters.finalFilesOnly = !_vm.filters.finalFilesOnly}}},[_c('i',{staticClass:"fad fa-file-alt file-final"})]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$modal.hide(_vm.$options.name)}}},[_c('i',{staticClass:"fal fa-times"})])]),_c('div',{staticClass:"content padding stages",staticStyle:{"min-height":"20em"}},[_c('vue-element-loading',{attrs:{"active":_vm.isLoading}}),(_vm.stagesFiltered.length === 0 && !_vm.isLoading)?_c('div',{staticClass:"txt-c",staticStyle:{"padding":"10%"}},[_vm._v(" Не найдено "),(_vm.filters.unreadOnly)?_c('strong',[_vm._v("непрочитанных")]):_vm._e(),_vm._v(" записей"),(_vm.filterFilesAsString.length > 0)?[_vm._v(", содержащих "),_c('strong',[_vm._v(_vm._s(_vm.filterFilesAsString))]),_vm._v(" файлы")]:_vm._e(),(_vm.filters.showHidden)?_c('strong',[_vm._v(" даже среди скрытых записей")]):_vm._e()],2):_vm._e(),_vm._l((_vm.stagesFiltered),function(stage){return _c('stage-entry',{key:stage.id,attrs:{"stage":stage,"group":_vm.group,"task":_vm.task,"hiddenShown":_vm.filters.showHidden},on:{"hidden":function($event){stage.isHidden = $event}}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }