<template>
	<div class="filters-picker">
		<button class="btn hidden"><i class="fad fa-sliders-v"></i></button>

    <span>
      <i class="fal fa-sliders-v"></i>
      <span class="smaller margin-half-h">фильтры:</span>
    </span>

    <span class="filter-entry" v-for="(filter, index) in filters" :key="index + 'filter'"
      v-bind:class="{active: filter.enabled}">
      <span class="inner" v-if="filter.type=='search'" @click="toggleFilter(filter)">
        <span v-if="filter.search_option == 'contains'">Содержит</span>
        <span v-if="filter.search_option == 'contains_not'">Не содержит</span>
        <span v-if="filter.search_option == 'contains_any'">Содержит любое из слов</span>
        &laquo;{{ filter.value }}&raquo;
      </span>

      <tippy v-if="filter.type=='assigned'" :interactive="true" placement="bottom-end" :maxWidth="550" trigger="click" theme="light sodnp">
        <template v-slot:trigger>
          <span class="inner">
            <span v-if="!filter.value.length">Ответственные: любые</span>
            <span v-else-if="filter.value.length === 1">Ответственные: {{ getFullName(filter.value[0]) }}</span>
            <span v-else-if="filter.search_option == 'one_in_cell'">В ячейке хотя бы 1 из {{ filter.value.length }} человек</span>
            <span v-else-if="filter.search_option == 'all_in_task'">В одной задаче все {{ filter.value.length }} человек</span>
            <span v-else-if="filter.search_option == 'all_in_cell'">В одной ячейке все {{ filter.value.length }} человек</span>
            <span v-else-if="filter.search_option == 'all_in_active_cell'">В активной ячейке все {{ filter.value.length }} человек</span>
          </span>
        </template>
      
        <users-picker v-model="filter.value" class="margin-half-b" @input="assignedUpdated(filter)"/>

        <div class="hidden">
          <div class="filter-divider"><span>Во всей задаче</span></div>
          <label class="block-vcenter">
            <input type="radio" class="margin-half-r" value="one_in_cell" v-model="addingFilter.search_option" />
            Любой из выбранных
          </label>
          <label class="block-vcenter">
            <input type="radio" class="margin-half-r" value="all_in_task" v-model="addingFilter.search_option" />
            Все выбранные
          </label>

          <div class="filter-divider"><span>В ячейке</span></div>
          <label class="block-vcenter">
            <input type="radio" class="margin-half-r" value="all_in_cell" v-model="addingFilter.search_option" />
            Весь список в одной ячейке 
          </label>
          <label class="block-vcenter">
            <input type="radio" class="margin-half-r" value="all_in_active_cell" v-model="addingFilter.search_option" />
            Весь список в <strong>активной</strong> ячейке
          </label>
          <label class="block-vcenter">
            <input type="radio" class="margin-half-r" value="one_in_active_cell" v-model="addingFilter.search_option" />
            Любой из выбранных в <strong>активной</strong> ячейке
          </label>
        </div>
      </tippy>


      <tippy v-if="filter.type=='assigned_specialization'" :interactive="true" placement="bottom-end" :maxWidth="550" trigger="click" theme="light sodnp">
        <template v-slot:trigger>
          <span class="inner">
            <span v-if="!filter.value.length">Специализации: любые</span>
            <span v-else-if="filter.value.length <= 3">Специализации: {{ getSpecializationsAsString(filter.value) }}</span>
            <span v-else>Специализации: {{ filter.value.length }}</span>
          </span>
        </template>
      
        <specializations-picker v-model="filter.value" class="margin-half-b" @input="assignedUpdated(filter)"/>
      </tippy>
      

      <span class="inner" v-if="filter.type=='removed'" @click="toggleFilter(filter)">
        <span v-if="filter.value == 'hide'">Скрывать удалённые</span>
        <span v-if="filter.value == 'show'">Показывать удалённые</span>
        <span v-if="filter.value == 'only_removed'">Только удалённые</span>
      </span>

      <tippy v-if="filter.type=='status'" :interactive="true" placement="bottom-end" :maxWidth="550" trigger="click" theme="light sodnp">
        <template v-slot:trigger>
          <span class="inner">
            Статус задачи:
            <span v-if="filter.value.length === 0">любой</span>
            <span v-else>{{ buildStatusString(filter.value) }}</span>
          </span>
        </template>
      
        <label class="block-vcenter">
          <input type="checkbox" class="margin-half-r" value="completed" v-model="filter.value" @change="statusUpdated(filter)" />
          Завершена
        </label>
        <label class="block-vcenter">
          <input type="checkbox" class="margin-half-r" value="in_progress" v-model="filter.value" @change="statusUpdated(filter)" />
          В работе
        </label>
        <label class="block-vcenter">
          <input type="checkbox" class="margin-half-r" value="stopped" v-model="filter.value" @change="statusUpdated(filter)" />
          Работы не ведутся
        </label>
      </tippy>

      <span class="inner" v-if="filter.type=='favorite'" @click="toggleFilter(filter)">
        Избранные
      </span>

      <span class="inner" v-if="filter.type=='notifications'" @click="toggleFilter(filter)">
        С уведомлениями
      </span>

      <span class="inner" v-if="filter.type=='attention'" @click="toggleFilter(filter)" title="Либо есть уведомления, либо я есть в назначенных и текущая стадия работы - моя">
        Ждут моих действий
      </span>

      <span class="inner" v-if="filter.type=='created_by_me'" @click="toggleFilter(filter)">
        Созданные мной
      </span>

      <span class="inner" v-if="filter.type=='only_mine'" @click="toggleFilter(filter)" title="Я есть среди назначенных на задачу на любой из стадий">
        Только мои задачи
      </span>

      <span class="inner" v-if="filter.type=='highlight_mine'" @click="toggleFilter(filter)" title="Выделить цветом задачи, в которых я есть среди назначенных на любой из стадий">
        Подсветить мои задачи
      </span>

      <span v-if="alwaysVisibleFilters.indexOf(filter.type)===-1" class="remove" @click="removeFilter(index)"><i class="fal fa-times"></i></span>
    </span>

    <div style="width: 2em; display: inline-block">
      <tippy :interactive="true" placement="bottom-end" :maxWidth="550" trigger="click" theme="light sodnp" ref="addingFilterTippy" :onHide="onFiltersHide">
        <template v-slot:trigger>
          <button class="btn full-width"><i class="fal fa-plus"></i></button>
        </template>

        <div class="filters-select">
          <div class="left-side">
            <span class="variant flex-row block-vcenter" @click="addingFilter = defaultSearchFilter" v-bind:class="{'active': addingFilter.type == 'search'}" title="Отфильтровать по названию задачи">
              <i class="fal fa-search"></i>
              <span class="flex-expand">Название</span>
              <i class="fal fa-angle-right arrow"></i>
            </span>
            <span class="variant flex-row block-vcenter" @click="addingFilter = defaultRemovedFilter" v-bind:class="{'active': addingFilter.type == 'removed'}">
              <i class="fal fa-trash-alt"></i>
              <span class="flex-expand">Удаленные</span>
              <i class="fal fa-angle-right arrow"></i>
            </span>

            <span class="variant flex-row block-vcenter" @click="addingFilter = defaultFavoritesFilter; addFilter()" title="Оставить только избранные (⭐) задачи">
              <i class="fal fa-star"></i>
              <span class="flex-expand">Избранные</span>
            </span>

            <span class="variant flex-row block-vcenter" @click="addingFilter = defaultNotificationsFilter; addFilter()">
              <i class="fal fa-exclamation-circle"></i>
              <span class="flex-expand">С уведомлениями</span>
            </span>

            <span class="variant flex-row block-vcenter" @click="addingFilter = defaultOnlyMineFilter; addFilter()" title="Я есть среди назначенных на задачу на любой из стадий">
              <i class="fal fa-male"></i>
              <span class="flex-expand">Только мои задачи</span>
            </span>

            <span class="variant flex-row block-vcenter" @click="addingFilter = defaultCreatedByMeFilter; addFilter()">
              <i class="fal fa-map-marker-smile"></i>
              <span class="flex-expand">Созданные мной</span>
            </span>

            <span class="hidden variant flex-row block-vcenter" @click="addingFilter = defaultDateFilter" v-bind:class="{'active': addingFilter.type == 'date'}">
              <i class="fad fa-calendar-alt"></i>
              <span class="flex-expand">Срок</span>
              <i class="fal fa-angle-right arrow"></i>
            </span>
          </div>



          <div class="right-side txt-l">
            <!-- поиск по названию -->
            <form class="padding-half-h" v-if="addingFilter.type == 'search'" style="width: 300px;" @submit.prevent="addFilter()">
              <input class="form-row" type="text" v-model="addingFilter.value" placeholder="Поиск..." />
              <label class="block-vcenter">
                <input type="radio" class="margin-half-r" value="contains" v-model="addingFilter.search_option" />
                Содержит текст
              </label>
              <label class="block-vcenter">
                <input type="radio" class="margin-half-r" value="contains_any" v-model="addingFilter.search_option" />
                Содержит любое из слов
              </label>
              <label class="block-vcenter">
                <input type="radio" class="margin-half-r" value="contains_not" v-model="addingFilter.search_option" />
                НЕ cодержит
              </label>
              
              <div class="pure-g container margin-half-v">
                <div class="pure-u-2-5">
                  <button class="btn full-width" type="button" @click="cancelFilter()">Отмена</button>
                </div>
                <div class="pure-u-3-5">
                  <button class="btn btn-primary full-width" type="button"  @click="addFilter()">Применить</button>
                </div>
              </div>
            </form>

            <!-- показывать/скрывать удалённые -->
            <form class="padding-half-h" v-if="addingFilter.type == 'removed'" style="width: 300px;" @submit.prevent="addFilter()">
              <label class="block-vcenter hidden">
                <input type="radio" class="margin-half-r" value="hide" v-model="addingFilter.value" />
                Скрывать
              </label>
              <label class="block-vcenter">
                <input type="radio" class="margin-half-r" value="show" v-model="addingFilter.value" />
                Показать
              </label>
              <label class="block-vcenter">
                <input type="radio" class="margin-half-r" value="only_removed" v-model="addingFilter.value" />
                Только удалённые
              </label>
              
              <div class="pure-g container margin-half-v">
                <div class="pure-u-2-5">
                  <button class="btn full-width" type="button" @click="cancelFilter()">Отмена</button>
                </div>
                <div class="pure-u-3-5">
                  <button class="btn btn-primary full-width" type="button"  @click="addFilter()">Применить</button>
                </div>
              </div>
            </form>

            <!-- поиск по сроку -->
            <div class="padding-half-h" v-if="addingFilter.type == 'date'" style="width: 300px;">
            </div>
          </div>
        </div>
      </tippy>
    </div>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
import UsersPicker from './UsersPicker.vue'
import SpecializationsPicker from './SpecializationsPicker.vue'

export default {
	name: 'FiltersPicker',
  components: {
      UsersPicker, SpecializationsPicker
  },
	props: ['value'],
	data() {
		return {
      alwaysVisibleFilters: [
        "highlight_mine", "attention", "status", "assigned", "assigned_specialization"
      ],
			filters: this.value,
      addingFilter: {}
		}
	},
  /*watch: {
    value() {
      this.filters = this.value
    }
  },*/
  computed: {
    ...mapGetters(["allUsersKeyed", "allSpecializations"]),
    defaultSearchFilter() {
      return {
        type: 'search',
        value: '',
        search_option: 'contains',
        enabled: true
      }
    },
    defaultAssignedFilter() {
      return {
        type: 'assigned',
        value: [],
        search_option: 'one_in_cell',
        enabled: true
      }
    },
    defaultRemovedFilter() {
      return {
        type: 'removed',
        value: 'hide',
        enabled: true
      }
    },
    defaultStatusFilter() {
      return {
        type: 'status',
        value: 'completed',
        enabled: true
      }
    },
    defaultDateFilter() {
      return {
        type: 'date',
        value: '',
        enabled: true
      }
    },
    defaultNotificationsFilter() {
      return {
        type: 'notifications',
        value: '',
        enabled: true
      }
    },
    defaultFavoritesFilter() {
      return {
        type: 'favorite',
        value: '',
        enabled: true
      }
    },
    defaultAttentionFilter() {
      return {
        type: 'attention',
        value: '',
        enabled: true
      }
    },
    defaultCreatedByMeFilter() {
      return {
        type: 'created_by_me',
        value: '',
        enabled: true
      }
    },
    defaultOnlyMineFilter() {
      return {
        type: 'only_mine',
        value: '',
        enabled: true
      }
    }
  },
	methods: {
    getFullName(uid) {
      console.log(this.allUsersKeyed[uid])
      return this.allUsersKeyed[uid].fullName()
    },
    getSpecializationsAsString(ids) {
      return this.allSpecializations.filter(s => ids.indexOf(s.id) !== -1).map(s => s.name).join(', ')
    },
		refreshValue() {
      this.$emit('input', this.filters.filter(f => f.enabled))
		},
    onFiltersHide() {
      this.filterSelectedVariant = null
      this.addingFilter = Object.assign({})
    },
    removeFilter(index) {
      this.filters.splice(index, 1)
      this.refreshValue()
    },
    cancelFilter() {
      this.$refs['addingFilterTippy'].tip.hide()
      this.refreshValue()
    },
    addFilter() {
      this.filters.push(Object.assign({}, this.addingFilter))
      this.addingFilter = Object.assign({})
      this.$refs['addingFilterTippy'].tip.hide()
      this.refreshValue()
    },
    toggleFilter(filter) {
      filter.enabled = !filter.enabled
      this.refreshValue()
    },
    buildStatusString(v) {
      let arr = []
      if (v.indexOf('completed') !== -1) {
        arr.push('завершена')
      }
      if (v.indexOf('in_progress') !== -1) {
        arr.push('в работе')
      }
      if (v.indexOf('stopped') !== -1) {
        arr.push('не в работе')
      }
      return arr.join(', ')
    },
    statusUpdated(filter) {
      filter.enabled = filter.value.length > 0
      this.refreshValue()
    },
    assignedUpdated(filter) {
      filter.enabled = filter.value.length > 0
      this.refreshValue()
    }
	}
}
</script>

<style scoped>
.filters-picker .btn {
  padding: .2em .5em;
}

.filters-select {
  display: flex;
  flex-direction: row;
}

.filters-select .right-side {
  padding: .5em 0 0 .5em;
}

.filters-select .variant {
  cursor: pointer;
}
.filters-select .variant:hover,
.filters-select .variant.active {
  background-color: #F5F6F6;
}
.filters-select .variant .flex-expand {
  padding: .25em 1em;
  text-align: left;
}
.filters-select .variant i.fal,
.filters-select .variant i.fad,
.filters-select .variant i.fas {
  display: inline-block;
  width: 1.75em;
  text-align: center;
}

.filters-select .variant .arrow {
  visibility: visible;
  font-size: 1.2em;
}

.filter-entry {
  display: inline-block;
  cursor: pointer;
  font-size: .8em;
  border-radius: .5em;
  margin-bottom: .5em;
  background-color: #F4F4F4;
  border: 1px solid #ddd;
  color: #444;
  margin-right: 1em;

  transition: all .2s ease-in-out;
}
.filter-entry.active {
  background-color: #509E2F;
  color: #FFF;
}

.filter-entry .inner {
    display: inline-block;
    height: 100%;
    line-height: 2;
    padding: .25em .5em;
    user-select: none;
}

.filter-entry .remove {
  display: inline-block;
  height: 100%;
  margin-left: .25em;
  padding: 0 .75em;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.filter-entry .remove:hover {
  box-shadow: inset 0 0 20em rgb(0, 0, 0, 0.07);
}

.filter-divider {
  position: relative;
  text-align: center;
}
.filter-divider span {
  display: inline-block;
  padding: 0 .5em;
  background-color: #FFF;
  z-index: 1;
}
.filter-divider:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  background-color: #CCC;
  z-index: -1;
}

b, strong {
  margin: 0 .5em;
}
</style>
