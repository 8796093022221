<template>
  <modal name="PopupAllStages" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :scrollable="true" :width="900" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">{{ task.name }}</span>

      <button class="btn" v-if="canHideStages" @click="filters.showHidden = !filters.showHidden" :class="{
        pressed: filters.showHidden
      }"><i class="fa-trash-alt" :class="{
        fal: !filters.showHidden,
        fas: filters.showHidden
      }"></i> Показать скрытые</button>

      <button class="btn" @click="filters.unreadOnly = !filters.unreadOnly" title="Показать только непрочитанные" :class="{
        pressed: filters.unreadOnly
      }"><i class="fa-envelope" :class="{
        fal: !filters.unreadOnly,
        fas: filters.unreadOnly
      }"></i>
        <span v-if="unreadCount > 0">({{ unreadCount }})</span>
      </button>

      <button class="btn" @click="filters.informationalFilesOnly = !filters.informationalFilesOnly" :class="{
        pressed: filters.informationalFilesOnly
      }" title="Содержащие новые информационные файлы"><i class="fad fa-file-alt file-info"></i></button>
      <button class="btn" @click="filters.workFilesOnly = !filters.workFilesOnly" :class="{
        pressed: filters.workFilesOnly
      }" title="Содержащие новые рабочие файлы"><i class="fad fa-file-alt file-working"></i></button>
      <button class="btn" @click="filters.finalFilesOnly = !filters.finalFilesOnly" :class="{
        pressed: filters.finalFilesOnly
      }" title="Содержащие новые итоговые файлы"><i class="fad fa-file-alt file-final"></i></button>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    <div class="content padding stages" style="min-height: 20em">
      <vue-element-loading :active="isLoading"/>
      <div class="txt-c" style="padding: 10%" v-if="stagesFiltered.length === 0 && !isLoading">
        Не найдено
        <strong v-if="filters.unreadOnly">непрочитанных</strong>
        записей<template v-if="filterFilesAsString.length > 0">, содержащих <strong>{{ filterFilesAsString }}</strong> файлы</template>
        <strong v-if="filters.showHidden"> даже среди скрытых записей</strong>
      </div>
      <stage-entry :stage="stage" :group="group" :task="task" v-for="stage in stagesFiltered" :key="stage.id" :hiddenShown="filters.showHidden" @hidden="stage.isHidden = $event" />
    </div>
  </modal>
</template>

<style scoped>
.btn.pressed {
  box-shadow: inset 0 0 20em rgb(144 193 75 / 34%);
}

.file-info {
  color: rgb(136, 136, 136);
}
.file-working {
  color: rgb(45, 112, 189);
}
.file-final {
  color: rgb(80, 158, 47);
}
</style>

<script>
import { mapGetters } from "vuex"

import VueElementLoading from 'vue-element-loading'
import Task from '@/models/task'
import apiCall from '@/utils/api'
import StageEntry from '@/components/StageEntry.vue'
import { MANAGE_STAGES } from "@/utils/permissions"
import { FILE_TYPE_WORKING, FILE_TYPE_FINAL, FILE_TYPE_INFORMATIONAL } from "@/utils/const"

export default {
  name: 'PopupAllStages',
  components: {
    VueElementLoading,
    StageEntry
  },
  data() {
    return {
      isLoading: false,
      task: Object.assign(new Task, {}),
      filters: {
        workFilesOnly: false,
        finalFilesOnly: false,
        informationalFilesOnly: false,
        unreadOnly: false,
        showHidden: false,
      },
      group: {},
      stages: []
    }
  },
  computed: {
    ...mapGetters(["authRoles", "notifications"]),
    stagesFiltered() {
			let stages = this.stages ?? []
      if (!this.filters.showHidden) {
        stages = stages.filter(s => !s.isHidden)
      }
      if (this.filters.workFilesOnly || this.filters.finalFilesOnly || this.filters.informationalFilesOnly) {
        let types = []
        if (this.filters.workFilesOnly) {
          types.push(FILE_TYPE_WORKING)
        }
        if (this.filters.finalFilesOnly) {
          types.push(FILE_TYPE_FINAL)
        }
        if (this.filters.informationalFilesOnly) {
          types.push(FILE_TYPE_INFORMATIONAL)
        }
        stages = stages.filter(s => (s.addedFiles.length && s.addedFiles.some(f => types.indexOf(f.type) !== -1 )) || (s.approvedFiles.length && s.approvedFiles.some(f => types.indexOf(f.type) !== -1 )))
      }
      if (this.filters.unreadOnly) {
        stages = stages.filter(s => this.notifications.stages[s.id] && this.notifications.stages[s.id].length)
      }
      return stages
		},
    filterFilesAsString() {
      let filters = []
      if (this.filters.workFilesOnly) {
        filters.push("рабочие")
      }
      if (this.filters.finalFilesOnly) {
        filters.push("итоговые")
      }
      if (this.filters.informationalFilesOnly) {
        filters.push("информационные")
      }
      let last = filters.pop()
      let filtersStr = filters.join(", ")
      if (last) {
        if (filters.length > 0) {
          filtersStr += " или "
        }
        filtersStr += last
      }
      return filtersStr
    },
    unreadCount() {
      let stages = this.stages ?? []
      stages = stages.filter(s => this.notifications.stages[s.id] && this.notifications.stages[s.id].length)
      return stages.length
    },
    canHideStages() {
      return this.authRoles.indexOf(MANAGE_STAGES) !== -1
    }
  },
	methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen(data) {
      this.task = data.params.task
      this.group = data.params.group
      this.stages = []
      this.loadStages()

      this.$escStack.push(this.$options.name)
    },
    loadStages() {
      this.isLoading = true
      apiCall({ url: 'stages/' + this.task.id, method: "GET"})
      .then((stages) => {
        this.stages = stages
        this.isLoading = false
      })
    }
	}
}
</script>