<template>
	<div>
		<multiselect
		class="tags-picker"
		v-model="selected"
		track-by="id"
		@search-change="asyncFind"
		@input="onChanged"
		placeholder="Выберите специализации"
		:multiple="true"
		:searchable="true"
		:loading="isLoading"
		:internal-search="false"
		:close-on-select="false"
		:options="specializations">
			<span slot="noResult">Ничего не найдено</span>
			<template slot="option" slot-scope="props">
				<span class="dropdown-option">
					<span class="option-tagged" v-bind:style="{'background-color': props.option.bgColor(), 'color': getTextColor(props.option.bgColor())}"><span style="opacity: 0;">__</span></span>
					{{ props.option.name }}
				</span>
			</template>
			<template slot="tag" slot-scope="{ option, remove }">
				<span class="tagged" v-bind:style="{'background-color': option.bgColor(), 'color': getTextColor(option.bgColor()) }">
					<span>{{ option.name }}</span>
					<span class="remove-tag" @click="remove(option)">
						<i class="fal fa-times"></i>
					</span>
				</span>
			</template>
		</multiselect>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { mapGetters } from "vuex"
import { SPECIALIZATIONS_REQUEST } from "@/store/actions/roles"

import textColor from '@/utils/textColor'
//import apiCall from "@/utils/api";
//import Role from '@/models/role';


export default {
	name: 'SpecializationsPicker',
	components: { Multiselect },
	props: ['value'],
	data () {
      return {
		isLoading: false,
		selected: null,
        specializations: []
      }
    },
	computed: {
		...mapGetters(["allSpecializations"])
	},
	watch: {
		allSpecializations(newVal) {
			this.updateSpecializations(newVal)
			if (this.value) {
				this.selected = this.specializations.filter(p => this.value.indexOf(p.id) !== -1)
			}
			this.isLoading = false
		}
	},
	mounted() {
		this.isLoading = true
		this.$store.dispatch(SPECIALIZATIONS_REQUEST)
	},
	methods: {
		asyncFind (query) {
			query = query.toLowerCase()
			this.isLoading = true
			/*apiCall({ url: "roles?query=" + query, method: "GET" })
			.then(resp => {
				this.updateRoles(resp)
				this.isLoading = false
			})*/
			this.updateSpecializations(this.allSpecializations.filter(r => {
				return r.name.toLowerCase().indexOf(query) !== -1
			}))
			this.isLoading = false
		},
		updateSpecializations(newSpecializations) {
			this.specializations = newSpecializations
		},
		onChanged(values) {
			this.$emit("input", values.map(p => p.id))
		},
		getTextColor(color) {
			return textColor(color)
		}
	}
}
</script>