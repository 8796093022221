import {
  PROJECT_REQUEST, PROJECT_ERROR, PROJECT_SUCCESS,
  GROUPS_REQUEST, GROUPS_SUCCESS, GROUPS_ERROR,
  FILTERS_UPDATED, LOCAL_TASK_ADDED, TASK_ADDED, TASK_UPDATED,
  START_SELECTING_UNASSIGNED, DONE_SELECTING_UNASSIGNED,
  START_LINKING_TASK, DONE_LINKING_TASK,
  START_LINKING_MULTIPLE, STOP_LINKING_MULTIPLE,
  STOP_SELECTING,
  SUBSCRIBE_TASK, UNSUBSCRIBE_TASK,
  TASK_CHILDREN_OPEN, TASK_CHILDREN_CLOSE,
  TASK_SHOW_START, TASK_SHOW_STOP,
  TASK_EDIT_NAME_START,
  TASK_PENDING_DELETED,
  CELLS_SELECTION_START, CELLS_SELECTION_STOP, CELLS_SELECTION_ADD, CELLS_SELECTION_DEL,
  GALLERY_CREATED,
  FORCE_SHOW_ASSIGNEE, FORCE_SHOW_DEADLINE
} from "../actions/project";
import apiCall from "@/utils/api";
import Project from '@/models/project';
import Task from '@/models/task'
import TaskGroup from '@/models/taskGroup'
import Vue from "vue";

const state = {
  status: "",
  project: Object.assign(new Project, {}),

  isSelectingUnassigned: false,
  isLinkingTask: false,
  isLinkingMultiple: false,
  selectingFor: null,

  groupsStatus: "",
  group: null,
  subscribed: {},  // задачи в проекте, на которые мы подписаны
  tasksKeyed: {},
  groupsKeyed: {},
  // задача, у которой открыты подздачи (чтоб затемнить всё остальное)
  taskChildrenOpenedId: null,
  taskShowing: {},
  filters: [],

  taskEditingName: null,

  // выбор строчек
  isSelectingCells: false,
  cellsSelected: [],

  galleriesCount: 0,
  forceShowAssignee: false,
  forceShowDeadline: false,
};

const getters = {
  isLoadingProject: state => state.status == 'loading' || state.groupsStatus == 'loading',
  activeProject: state => state.project,
  activeGroup: state => state.group,
  tasksSubscribed: state => state.subscribed,
  tasksKeyed: state => state.tasksKeyed,
  groupsKeyed: state => state.groupsKeyed,
  taskChildrenOpenedId: state => state.taskChildrenOpenedId,
  highlightMyTasks: state => state.filters.some(f => f.enabled && f.type == "highlight_mine"),

  isSelectingUnassigned: state => state.isSelectingUnassigned,
  isLinkingTask: state => state.isLinkingTask,
  isLinkingMultiple: state => state.isLinkingMultiple,

  selectingFor: state => state.selectingFor,
  taskShowing: state => state.taskShowing,
  galleriesCount: state => state.galleriesCount,

  taskEditingName: state => state.taskEditingName,

  isSelectingCells: state => state.isSelectingCells,
  cellsSelected: state => state.cellsSelected,

  forceShowAssignee: state => state.forceShowAssignee,
  forceShowDeadline: state => state.forceShowDeadline,
};

function findGroupRecursive(root, groupId) {
  if (root.id == groupId) {
    return root
  }
  for (var i = 0; i < root.groups.length; i++) {
    var checked = findGroupRecursive(root.groups[i], groupId)
    if (checked) {
      return checked
    }
  }
  for (var j = 0; j < root.tasks.length; j++) {
    for (var k = 0; k < root.tasks[j].groups.length; k++) {
      var checked2 = findGroupRecursive(root.tasks[j].groups[k], groupId)
      if (checked2) {
        return checked2
      }
    }
  }
  return null
}
function buildTasksKeyedRecursive(group) {
  let tasks = {}, groups = {}
  groups[group.id] = group
  for (var i = 0; i < group.groups.length; i++) {
    let subtasks_subgroups = buildTasksKeyedRecursive(group.groups[i])
    tasks = Object.assign(tasks, subtasks_subgroups[0])
    groups = Object.assign(groups, subtasks_subgroups[1])
  }
  for (var j = 0; j < group.tasks.length; j++) {
    tasks[group.tasks[j].id] = group.tasks[j]
    for (var k = 0; k < group.tasks[j].groups.length; k++) {
      let t_subtasks_subgroups = buildTasksKeyedRecursive(group.tasks[j].groups[k])
      tasks = Object.assign(tasks, t_subtasks_subgroups[0])
      groups = Object.assign(groups, t_subtasks_subgroups[1])
    }
  }
  return [tasks, groups]
}







// ФИЛЬТРЫ
function filteredTasksIds(tasks, filters, authId, notifications, allUsersKeyed, state) {
  // заполняем у всех переданных задач какие подгруппы и подзадачи видимы
  tasks.map(t => {
    t.visibleTaskIds = filteredTasksIds(t.tasks, filters, authId, notifications, allUsersKeyed, state)
    t.visibleGroupIds = filteredGroupsIds(t.groups, filters, authId, notifications, allUsersKeyed, state)
    // имеет подзадачи - если либо если прямые подзадачи, либо есть подгруппа, которая имеет подзадачи
    t.hasSubtasks = (t.visibleTaskIds && Object.keys(t.visibleTaskIds).length) || t.groups.some(g => g.hasSubtasks)
    return t
  })
  return tasks.filter(t => {
    // if "removed" filter not specified, hide removed by default:
    if (!filters.find(f => f.type == 'removed') && !checkRemovedFilter({ type: "removed", value: "hide", enabled: true }, t)) {
      return false
    }
    for (var i = 0; i < filters.length; i++) {
      let f = filters[i]
      if (!f.enabled) {
        continue;
      }
      if (f.type == 'search' && !checkSearchFilter(f, t)) {
        return false
      }
      if (f.type == 'assigned' && !checkAssignedFilter(f, t)) {
        return false
      }
      if (f.type == 'assigned_specialization' && !checkAssignedSpecializationFilter(f, t, allUsersKeyed)) {
        return false
      }
      if (f.type == 'removed' && !checkRemovedFilter(f, t)) {
        return false
      }
      if (f.type == 'status' && !checkStatusFilter(f, t)) {
        return false
      }
      if (f.type == 'favorite' && !checkFavoriteFilter(f, t, state)) {
        return false
      }
      if (f.type == 'notifications' && !checkNotificationsFilter(f, t, notifications)) {
        return false
      }
      if (f.type == 'attention' && !checkAttentionFilter(f, t, notifications, authId)) {
        return false
      }
      if (f.type == 'created_by_me' && !checkCreatedByMeFilter(f, t, authId)) {
        return false
      }
      if (f.type == 'only_mine' && !checkOnlyMineFilter(f, t, authId)) {
        return false
      }
    }
    return true
  }).reduce((acc, curr) => (acc[curr.id] = true, acc), {});
}

function filteredGroupsIds(groups, filters, authId, notifications, allUsersKeyed, state) {
  return groups.map(g => {
    g.visibleTaskIds = filteredTasksIds(g.tasks, filters, authId, notifications, allUsersKeyed, state)
    g.visibleGroupIds = filteredGroupsIds(g.groups, filters, authId, notifications, allUsersKeyed, state)
    // имеет подзадачи - если либо если прямые подзадачи, либо есть подгруппа, которая имеет подзадачи
    g.hasSubtasks = g.visibleTaskIds && Object.keys(g.visibleTaskIds).length || g.groups.some(g => g.hasSubtasks)
    return g
  }).reduce((acc, curr) => (acc[curr.id] = true, acc), {});
}

// фильтр "поиск по строке"
function checkSearchFilter(filter, task) {
  let name = task.name.toLowerCase()
  let search = filter.value.toLowerCase()
  // строка содержит искомую подстроку
  if (filter.search_option == 'contains' && name.indexOf(search) === -1) {
    return false
  }
  // строка НЕ(!) содержит искомую подстроку
  if (filter.search_option == 'contains_not' && name.indexOf(search) !== -1) {
    return false
  }
  // строка содержит любое из слов в поиске
  if (filter.search_option == 'contains_any' && !search.trim().split(' ').some(function (v) { return name.indexOf(v) >= 0; })) {
    return false
  }
  return true
}

function checkAssignedFilter(filter, task) {
  let ids = filter.value
  if (ids.length === 0) {
    return true
  }
  // хотя бы один из переданного списка ids хотя бы в одной ячейке
  if (filter.search_option == 'one_in_cell') {
    for (var i = 0; i < task.cells.length; i++) {
      if (task.cells[i].assignedIds.some(id => ids.findIndex(_id => _id == id) !== -1)) {
        return true
      }
    }
    return false
  }
  // в задаче должны быть упомянуты ВСЕ из переданного списка
  if (filter.search_option == 'all_in_task') {
    for (var j = 0; j < task.cells.length; j++) {
      // удаляем из списка ids те, которые встретились
      // то есть оставляем тех, кто еще ни разу не встретился
      ids = ids.filter(id => task.cells[j].assignedIds.findIndex(_id => _id == id) === -1)
      // как только он стал пуст - значит условие выполнено
      if (ids.length === 0) {
        return true
      }
    }
    return false
  }
  // в одной из ячеек должны быть упомянуты ВСЕ из переданного списка
  if (filter.search_option == 'all_in_cell') {
    for (var k = 0; k < task.cells.length; k++) {
      if (ids.every(id => task.cells[k].assignedIds.findIndex(_id => _id == id) !== -1)) {
        return true
      }
    }
    return false
  }

  // в АКТИВНОЙ (которая на данный момент последняя в очереди) должен быть 
  // упоменут ЛЮБОЙ из переданного списка
  if (filter.search_option == 'one_in_active_cell') {
    for (var m = task.cells.length - 1; m >= 0; m--) {
      // ищем "активную" ячейку:
      // если эта задача "не в работе", а предыдущая "не выполнена"
      // то переходим на предыдущую
      if (m > 0 && task.cells[m - 1].status < 90) {
        continue;
      }
      // в активной проверяем список
      if (task.cells[m].assignedIds.some(id => ids.findIndex(_id => _id == id) !== -1)) {
        return true
      }
    }
    return false;
  }

  // в АКТИВНОЙ (которая на данный момент последняя в очереди) должны быть 
  // упоменуты ВСЕ из переданного списка
  if (filter.search_option == 'all_in_active_cell') {
    for (var l = task.cells.length - 1; l >= 0; l--) {
      // ищем "активную" ячейку:
      // если эта задача "не в работе", а предыдущая "не выполнена"
      // то переходим на предыдущую
      if (l > 0 && task.cells[l - 1].status < 90) {
        continue;
      }
      // в активной проверяем список
      return ids.every(id => task.cells[l].assignedIds.findIndex(_id => _id == id) !== -1)
    }
    return false;
  }
  return true
}


function checkAssignedSpecializationFilter(filter, task, allUsersKeyed) {
  let ids = filter.value
  if (ids.length === 0) {
    return true
  }

  // хотя бы один из переданного списка ids хотя бы в одной ячейке
  for (var i = 0; i < task.cells.length; i++) {
    if (task.cells[i].assignedIds.some(id => ids.includes(allUsersKeyed[id].specializationId))) {
      return true
    }
  }
  return false
}


// фильтр "удалённые"
function checkRemovedFilter(filter, task) {
  if (filter.value == 'hide') {
    return !task.isDeleted && !!filter.enabled
  }
  if (filter.value == 'only_removed') {
    return !!task.isDeleted && !!filter.enabled
  }
  return !task.isDeleted || (task.isDeleted && filter.enabled)
}
// фильтр "статус задачи"
function checkStatusFilter(filter, task) {
  if (!filter.value) {
    return true
  }
  for (var i = task.cells.length - 1; i >= 0; i--) {
    // ищем "активную" ячейку:
    // если эта задача "не в работе", а предыдущая "не выполнена"
    // то переходим на предыдущую
    if (i > 0 && task.cells[i].status <= 0 && task.cells[i - 1].status < 90) {
      continue;
    }
    // в активной проверяем статус
    if (task.cells[i].status >= 90) {
      return filter.value.indexOf('completed') !== -1
    } else if (task.cells[i].status == 20) {
      return filter.value.indexOf('in_progress') !== -1
    } else {
      return filter.value.indexOf('stopped') !== -1
    }
  }
  return true
}
// фильтр "только избранные"
function checkFavoriteFilter(filter, task, state) {
  return !!state.subscribed[task.id]
}
// фильтр "только с уведомлениями"
function checkNotificationsFilter(filter, task, notifications) {
  for (var i = 0; i < task.cells.length; i++) {
    // либо обычное уведомление
    if (notifications.cells[task.cells[i].id] && notifications.cells[task.cells[i].id].length) {
      return true
    }
    // либо уведомление чата
    if (notifications.chatTasks[task.id] && notifications.chatTasks[task.id].length) {
      return true
    }
  }
  return false
}
// фильтр "ждут моих действий": назначены на меня + моя очередь
function checkAttentionFilter(filter, task, notifications, authId) {
  // нераспределенные - пока всегда отображаем
  // TODO: в перспективе - только тем кто может распределить
  if (!task.groupId) {
    return true
  }

  let notificationsFilter = { type: 'notifications' }
  // либо есть уведомление
  if (checkNotificationsFilter(notificationsFilter, task, notifications)) {
    return true
  }

  // либо активная ячейка сейчас назначеня на меня
  let assignedInActiveFilter = {
    type: 'assigned',
    value: [authId],
    search_option: 'one_in_active_cell'
  }
  if (task.status != 90 && checkAssignedFilter(assignedInActiveFilter, task)) {
    return true
  }

  return false
}

// фильтр созданные мной
function checkCreatedByMeFilter(filter, task, authId) {
  return task.createdById == authId
}

function checkOnlyMineFilter(filter, task, authId) {
  return checkAssignedFilter({
    type: 'assigned',
    value: [authId],
    search_option: 'one_in_cell'
  }, task)
}






const actions = {
  [PROJECT_REQUEST]: ({ commit, dispatch }, projectData) => {
    commit(PROJECT_REQUEST);
    apiCall({ url: "projects/" + projectData.projectId })
      .then(resp => {
        commit(PROJECT_SUCCESS, Object.assign(new Project, resp));
        dispatch(GROUPS_REQUEST)
      })
      .catch(() => {
        commit(PROJECT_ERROR);
      });
  },

  [GROUPS_REQUEST]: ({ commit, state, rootState }) => {
    commit(GROUPS_REQUEST);
    apiCall({ url: "task-groups/" + state.project.id, method: "GET" })
      .then(resp => {
        commit(GROUPS_SUCCESS, {
          data: resp,
          authId: rootState.auth.uid,
          notifications: rootState.notifications.notifications,
          allUsersKeyed: rootState.users.usersKeyed
        });
      })
      .catch(() => {
        commit(GROUPS_ERROR);
      });
  },

  [FILTERS_UPDATED]: ({ commit, state, rootState }, filters) => {
    commit(FILTERS_UPDATED, {
      filters: filters ? filters : state.filters,
      authId: rootState.auth.uid,
      notifications: rootState.notifications.notifications,
      allUsersKeyed: rootState.users.usersKeyed
    });
  },

  [LOCAL_TASK_ADDED]: ({ commit }, data) => {
    commit(LOCAL_TASK_ADDED, data);
  },
  [TASK_ADDED]: ({ commit, dispatch, state }, data) => {
    if (state.group.projectId != data.projectId) {
      return
    }

    console.log(`loading new row with id=${data.taskId} from server`)
    apiCall({ url: "tasks/" + data.taskId, method: "GET" })
      .then((resp) => {
        let task = Object.assign(new Task, resp, { pendingId: data.pendingId })
        commit(TASK_ADDED, task);
        dispatch(FILTERS_UPDATED)
      })
  },
  [TASK_UPDATED]: ({ commit, dispatch, state }, data) => {
    if (state.group.projectId != data.projectId) {
      return
    }

    console.log(`reload row with id=${data.taskId} from server`)
    apiCall({ url: "tasks/" + data.taskId, method: "GET" })
      .then((resp) => {
        let group = findGroupRecursive(state.group, data.groupFrom !== undefined ? data.groupFrom : data.groupId)
        let index = group.tasks.findIndex(t => t.id == data.taskId)
        let task = Object.assign(new Task, group.tasks[index], resp, {
          // группы и задачи не подгрузятся, поэтому их оставляем как есть
          groups: group.tasks[index].groups,
          tasks: group.tasks[index].tasks,
        })
        commit(TASK_UPDATED, {
          data: data,
          task: task
        });
        dispatch(FILTERS_UPDATED)

        if (data.withSubgroups) {
          apiCall({ url: "task-groups/task/" + data.taskId + "/groups", method: "GET" })
            .then((resp) => {
              let groups = []
              for (var i = 0; i < resp.length; i++) {
                var before = task.groups.find(g => g.id == resp[i].id)
                if (!before) {
                  groups.push(Object.assign(new TaskGroup, resp[i]))
                } else {
                  groups.push(Object.assign(new TaskGroup, before, resp[i], {
                    // группы и задачи не подгрузятся, поэтому их оставляем как есть
                    groups: before.groups,
                    tasks: before.tasks,
                  }))
                }
              }
              task.groups = groups

              commit(TASK_UPDATED, {
                data: data,
                task: task
              });
              dispatch(FILTERS_UPDATED)
            })
        }
      })

  },

  [START_SELECTING_UNASSIGNED]: ({ commit }, task) => {
    commit(START_SELECTING_UNASSIGNED, task);
  },
  [DONE_SELECTING_UNASSIGNED]: ({ commit, state }, group) => {
    apiCall({ url: "tasks/" + state.selectingFor.id + "/move/" + group.id, method: "POST" })
      .then(() => {
        commit(DONE_SELECTING_UNASSIGNED);
      })
      .catch(() => {
        commit(STOP_SELECTING);
      });
    commit(STOP_SELECTING);
  },

  [START_LINKING_TASK]: ({ commit }, task) => {
    commit(START_LINKING_TASK, task);
  },
  [DONE_LINKING_TASK]: ({ commit, state }, task) => {
    apiCall({ url: "tasks/" + task.id + "/depends-on/" + state.selectingFor.id, method: "POST" })
      .then(() => {
        if (!state.isLinkingMultiple) {
          commit(DONE_LINKING_TASK);
        }
      })
      .finally(() => {
        if (!state.isLinkingMultiple) {
          commit(STOP_SELECTING);
        }
      });
  },

  [START_LINKING_MULTIPLE]: ({ commit }) => {
    commit(START_LINKING_MULTIPLE);
  },
  [STOP_LINKING_MULTIPLE]: ({ commit }) => {
    commit(STOP_LINKING_MULTIPLE);
  },

  [STOP_SELECTING]: ({ commit }) => {
    commit(STOP_SELECTING);
  },

  [SUBSCRIBE_TASK]: ({ commit }, task) => {
    apiCall({ url: "tasks/" + task.id + "/subscribe", method: "POST" })
      .then(() => {
        commit(SUBSCRIBE_TASK, task);
      })
  },
  [UNSUBSCRIBE_TASK]: ({ commit }, task) => {
    apiCall({ url: "tasks/" + task.id + "/unsubscribe", method: "POST" })
      .then(() => {
        commit(UNSUBSCRIBE_TASK, task);
      })
  },

  [TASK_CHILDREN_OPEN]: ({ commit }, taskId) => {
    commit(TASK_CHILDREN_OPEN, taskId);
  },
  [TASK_CHILDREN_CLOSE]: ({ commit }) => {
    commit(TASK_CHILDREN_CLOSE);
  },

  [TASK_SHOW_START]: ({ commit }, task) => {
    commit(TASK_SHOW_START, task);
  },
  [TASK_SHOW_STOP]: ({ commit }) => {
    commit(TASK_SHOW_STOP);
  },

  [TASK_EDIT_NAME_START]: ({ commit }, task) => {
    commit(TASK_EDIT_NAME_START, task);
  },

  [TASK_PENDING_DELETED]: ({ commit }, { group, task }) => {
    commit(TASK_PENDING_DELETED, { group, task });
  },

  [CELLS_SELECTION_START]: ({ commit }) => {
    commit(CELLS_SELECTION_START);
  },
  [CELLS_SELECTION_STOP]: ({ commit }) => {
    commit(CELLS_SELECTION_STOP);
  },
  [CELLS_SELECTION_ADD]: ({ commit }, cell) => {
    commit(CELLS_SELECTION_ADD, cell);
  },
  [CELLS_SELECTION_DEL]: ({ commit }, cell) => {
    commit(CELLS_SELECTION_DEL, cell);
  },

  [GALLERY_CREATED]: ({ commit }) => {
    commit(GALLERY_CREATED);
  },

  [FORCE_SHOW_ASSIGNEE]: ({ commit }, force) => {
    commit(FORCE_SHOW_ASSIGNEE, force)
  },
  [FORCE_SHOW_DEADLINE]: ({ commit }, force) => {
    commit(FORCE_SHOW_DEADLINE, force)
  },
};

const mutations = {
  [PROJECT_REQUEST]: state => {
    state.status = "loading";
  },
  [PROJECT_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "project", resp);
  },
  [PROJECT_ERROR]: state => {
    state.status = "error";
  },

  [FILTERS_UPDATED]: (state, data) => {
    state.filters = data.filters
    state.group.visibleTaskIds = filteredTasksIds(state.group.tasks, data.filters, data.authId, data.notifications, data.allUsersKeyed, state)
    state.group.visibleGroupIds = filteredGroupsIds(state.group.groups, data.filters, data.authId, data.notifications, data.allUsersKeyed, state)
    state.group.hasSubtasks = true
    Vue.set(state, "group", state.group);
  },

  [GROUPS_REQUEST]: state => {
    state.groupsStatus = "loading";
  },
  [GROUPS_SUCCESS]: (state, data) => {
    state.groupsStatus = "success";
    data.data.group.visibleTaskIds = filteredTasksIds(data.data.group.tasks, state.filters, data.authId, data.notifications, data.allUsersKeyed, state)
    data.data.group.visibleGroupIds = filteredGroupsIds(data.data.group.groups, state.filters, data.authId, data.notifications, data.allUsersKeyed, state)
    data.data.group.hasSubtasks = true
    Vue.set(state, "group", data.data.group);

    let subscribed = {}
    for (var i = 0; i < data.data.subscribedTasks.length; i++) {
      subscribed[data.data.subscribedTasks[i]] = 1
    }
    Vue.set(state, "subscribed", subscribed);

    let tasks_groups = buildTasksKeyedRecursive(data.data.group)
    Vue.set(state, "tasksKeyed", tasks_groups[0])
    Vue.set(state, "groupsKeyed", tasks_groups[1])
  },
  [GROUPS_ERROR]: state => {
    state.groupsStatus = "error";
  },

  [LOCAL_TASK_ADDED]: (state, data) => {
    let group = findGroupRecursive(state.group, data.groupId ?? 0)
    Vue.set(group.tasks, group.tasks.length, data.task)
  },
  [TASK_ADDED]: (state, task) => {
    let group = findGroupRecursive(state.group, task.groupId ?? 0)
    // self created task == we already have this id
    let selfCreatedIndex = group.tasks.findIndex(t => t.pendingId == task.pendingId)
    if (selfCreatedIndex !== -1) {
      Vue.set(group.tasks, selfCreatedIndex, task)
    } else {
      Vue.set(group.tasks, group.tasks.length, task)
    }
    Vue.set(state.tasksKeyed, task.id, task)
  },
  [TASK_UPDATED]: (state, data) => {
    // задачу перенесли из нашей группы куда-то
    if (data.data.groupFrom || data.data.groupTo) {
      let groupFrom = findGroupRecursive(state.group, data.data.groupFrom)
      let groupTo = findGroupRecursive(state.group, data.data.groupTo)
      let index = groupFrom.tasks.findIndex(t => t.id == data.data.taskId)
      groupFrom.tasks.splice(index, 1)
      Vue.set(groupTo.tasks, groupTo.tasks.length, data.task)
    }
    let group = findGroupRecursive(state.group, data.data.groupId)
    let taskIndex = group.tasks.findIndex(t => t.id == data.data.taskId)
    Vue.set(group.tasks, taskIndex, data.task)
    Vue.set(state.tasksKeyed, data.task.id, data.task)
    for (var g of data.task.groups) {
      Vue.set(state.groupsKeyed, g.id, g)
    }
  },

  [START_SELECTING_UNASSIGNED]: (state, task) => {
    state.isSelectingUnassigned = true
    state.selectingFor = task
  },
  [DONE_SELECTING_UNASSIGNED]: (state) => {
    state.isSelectingUnassigned = false
  },

  [START_LINKING_TASK]: (state, task) => {
    state.isLinkingTask = true
    state.selectingFor = task
  },
  [DONE_LINKING_TASK]: (state) => {
    state.isSelectingUnassigned = false
    state.isLinkingMultiple = false
  },

  [START_LINKING_MULTIPLE]: (state) => {
    state.isLinkingMultiple = true
  },
  [STOP_LINKING_MULTIPLE]: (state) => {
    state.isLinkingMultiple = false
  },

  [STOP_SELECTING]: (state) => {
    state.isSelectingUnassigned = false
    state.isLinkingTask = false
    state.selectingFor = null
  },

  [SUBSCRIBE_TASK]: (state, task) => {
    let subscribed = Object.assign({}, state.subscribed)
    subscribed[task.id] = 1
    Vue.set(state, "subscribed", subscribed);
  },
  [UNSUBSCRIBE_TASK]: (state, task) => {
    let subscribed = Object.assign({}, state.subscribed)
    delete subscribed[task.id]
    Vue.set(state, "subscribed", subscribed);
  },

  [TASK_CHILDREN_OPEN]: (state, taskId) => {
    Vue.set(state, "taskChildrenOpenedId", taskId)
  },
  [TASK_CHILDREN_CLOSE]: (state) => {
    Vue.set(state, "taskChildrenOpenedId", null)
  },

  [TASK_SHOW_START]: (state, task) => {
    Vue.set(state, "taskShowing", task)
  },
  [TASK_SHOW_STOP]: (state) => {
    Vue.set(state, "taskShowing", {})
  },

  [TASK_EDIT_NAME_START]: (state, task) => {
    Vue.set(state, "taskEditingName", task.id ? task.id : task.pendingId)
  },

  [TASK_PENDING_DELETED]: (state, { group, task }) => {
    let stateGroup = findGroupRecursive(state.group, group.id)
    let index = stateGroup.tasks.findIndex(t => t.pendingId == task.pendingId)
    stateGroup.tasks.splice(index, 1)
  },

  [CELLS_SELECTION_START]: (state) => {
    Vue.set(state, "isSelectingCells", true)
    state.cellsSelected = []
  },
  [CELLS_SELECTION_STOP]: (state) => {
    Vue.set(state, "isSelectingCells", false)
    state.cellsSelected = []
  },
  [CELLS_SELECTION_ADD]: (state, cell) => {
    state.cellsSelected.push(cell)
  },
  [CELLS_SELECTION_DEL]: (state, cell) => {
    state.cellsSelected = state.cellsSelected.filter(c => c.id != cell.id)
  },

  [GALLERY_CREATED]: (state) => {
    state.galleriesCount += 1
  },

  [FORCE_SHOW_ASSIGNEE]: (state, data) => {
    state.forceShowAssignee = !!data.force
    if (state.forceShowAssignee) {
      state.forceShowDeadline = false
    }
  },
  [FORCE_SHOW_DEADLINE]: (state, data) => {
    state.forceShowDeadline = !!data.force
    if (state.forceShowDeadline) {
      state.forceShowAssignee = false
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
